const CDN_BASE_ORIGIN = "https://cdn.cryptocity.tw/assets";
const CDN_BASE = "https://cdn.cryptocity.tw/assets/images/cc/v4/";

// 加密城市相關連結
const CRYPTO_CITY_OFFICIAL_URL = {
  CC: "https://www.cryptocity.tw/",
  CS: "https://shop.cryptocity.tw/",
  APP: "https://cryptocity.onelink.me/lRjo/QRCODELINK",
  FB: "https://www.facebook.com/CryptoCitytw",
  IG: "https://www.instagram.com/cryptocitytw/",
  TWITTER: "https://twitter.com/CryptoCitytw",
  LINE: "https://line.me/ti/g2/1pEXLLPSV_s3ZOuFFlPCzHD6lwagtDCNF6vh1w?utm_source=invitation&utm_medium=link_copy&utm_campaign=default",
  TELEGRAM: "https://t.me/cryptocitytw",
  LINE_AT: "https://line.me/R/ti/p/@618dhvkq",
  THREADS: "https://www.threads.net/@cryptocitytw",
  DC: "https://discord.gg/gGxKcxcDS3",
  APPLE:
    "https://apps.apple.com/tw/app/%E5%8A%A0%E5%AF%86%E5%9F%8E%E5%B8%82-%E6%9C%80%E5%85%A8%E9%9D%A2%E7%9A%84%E5%8D%80%E5%A1%8A%E9%8F%88%E8%B3%87%E8%A8%8A%E5%B9%B3%E5%8F%B0/id1661753227",
  GOOGLE:
    "https://play.google.com/store/apps/details?id=com.cryptocity.cc&hl=zh_TW&gl=US",
  RECRUIT: "https://www.104.com.tw/company/1a2x6blwp6",
  META_FACEBOOK_IMAGE: CDN_BASE + "facebook_share.jpg",
  META_TWITTER_IMAGE: CDN_BASE + "twitter_share.jpg",
  CR: "https://www.cryptocity.tw/crypto-read",
};

// podcast 連結
const PODCAST_OFFICIAL_URL = {
  APPLE:
    "https://podcasts.apple.com/tw/podcast/%E5%8A%A0%E5%AF%86%E5%90%A7-crypto-bar/id1638302603",
  SPOTIFY: "https://open.spotify.com/show/4KnjrvQgpU5tXZ38RFTeXJ",
  SOUNDON: "https://player.soundon.fm/p/c4b24f8a-d3bc-4fe2-8b77-aa6e24ddc747",
  KKBOX: "https://podcast.kkbox.com/tw/channel/CngdslbMSx8Z00oEOW",
  FIRSTORY: "https://open.firstory.me/user/cryptocity",
};

// 全站導覽列分類
const NAV_MENU = {
  NEWS: "NEWS",
  PROJECT: "PROJECT",
  AUTHOR: "AUTHOR",
  FLASH: "FLASH",
  // ACADEMY: "ACADEMY",
  EVENTS: "EVENTS",
  PODCAST: "PODCAST",
  SHOP: "SHOP",
};

const NAV_MENU_MAP = {
  NEWS: "新聞",
  PROJECT: "專題",
  AUTHOR: "專欄",
  FLASH: "快訊",
  // ACADEMY: "學院",
  EVENTS: "活動",
  PODCAST: "Podcast",
  SHOP: "加密市集",
};

// 使用者選單連結
const USER_MENU_LINK = [
  {
    title: "ACCOUNT",
    link: "",
  },
  {
    title: "SUB",
    link: "subscribe-newsletter",
  },
  {
    title: "TRANSACTION",
    link: "transaction-record",
  },
  {
    title: "SECURITY",
    link: "account-security",
  },
];

// footer 頁面連結
const FOOTER_PAGE_LINK = [
  { title: "ABOUT", link: "/about" },

  { title: "RECRUIT", link: CRYPTO_CITY_OFFICIAL_URL.RECRUIT, blank: true },
  {
    title: "CONTACT",
  },
  {
    title: "COOPERATE",
  },
  {
    title: "RSS",
    link: "https://www.cryptocity.tw/rss",
    blank: true,
  },
  {
    title: "TERMS",
    link: "/privacy-policy",
  },
];

// footer 社群連結
const FOOTER_SOCIAL_LINK = [
  { source: "facebook", link: CRYPTO_CITY_OFFICIAL_URL.FB },
  { source: "telegram", link: CRYPTO_CITY_OFFICIAL_URL.TELEGRAM },
  { source: "twitter", link: CRYPTO_CITY_OFFICIAL_URL.TWITTER },
  { source: "line", link: CRYPTO_CITY_OFFICIAL_URL.LINE },
  { source: "instagram", link: CRYPTO_CITY_OFFICIAL_URL.IG },
];

// 新聞類別
const NEWS_CATEGORY_MAP = {
  HOT: "Hot", // 即時
  DEFI: "DeFi", // 金融
  INTL: "INTL", // 國際
  VIEW: "View", // 觀點
  SOCIAL: "Social", // 社會
  SUPERVISE: "Supervise", // 監管
  BUSINESS: "Business", // 商業
  TECH: "Tech", // 技術
  AI: "AI", // AI
  AIRDROP: "Airdrop", // 空投
  GAMEFI: "GameFi", // 遊戲
  NFT: "NFT", // NFT
  NEWBIE: "Newbie", // 特別企劃
};

const FAKE_BANNER = [
  {
    subject:
      "說一套做一套？摩根大通才喊加密貨幣沒有希望，轉身就宣布持有比特幣！",
    subjectCn:
      "说一套做一套？摩根大通才喊加密货币没有希望，转身就宣布持有比特币！",
    summary:
      "摩根大通宣布持有價值約 100 萬美元的比特幣現貨ETF，顯示其對數位貨幣市場策略的重大轉變。儘管銀行高層曾對加密貨幣持懷疑態度，此舉反映了金融市場對加密貨幣接受度的提升。",
    summaryCn:
      "摩根大通宣布持有价值约 100 万美元的比特币现货ETF，显示其对数位货币市场策略的重大转变。儘管银行高层曾对加密货币持怀疑态度，此举反映了金融市场对加密货币接受度的提升。",
  },
  {
    subject:
      "比特幣沖高回落！大暴跌還在後頭？分析師揭波浪理論：恐摔回「這點位」",
    subjectCn:
      "比特币冲高回落！大暴跌还在后头？分析师揭波浪理论：恐摔回「这点位」",
    summary:
      "比特幣今早一度跌破 65,000 美元，隨後回升。鑑於比特幣走勢疲軟，交易員與分析師正在爭論市場是否會進一步下跌，以及能跌到多低？",
    summaryCn:
      "比特币今早一度跌破 65,000 美元，随后回升。鑑于比特币走势疲软，交易员与分析师正在争论市场是否会进一步下跌，以及能跌到多低？",
  },
];

const FAKE_SELECTED = [
  {
    subject: "呼籲把比特幣納入州儲備！美議員：若八年前這樣做，國庫早就暴漲百倍",
    subjectCn:
      "呼吁把比特币纳入州储备！美议员：若八年前这样做，国库早就暴涨百倍",
  },
  {
    subject:
      "40 年日本遊戲軟實力！Oasys 公鏈全面介紹，如何把傳統遊戲帶向綠洲？",
    subjectCn:
      "40 年日本游戏软实力！Oasys 公链全面介绍，如何把传统游戏带向绿洲？",
  },
  {
    subject: "一鍵秒發迷因幣！Pump.fun 是什麼？發幣教學、平台特色一次看",
    subjectCn: "一键秒发迷因币！Pump.fun 是什麽？发币教学、平台特色一次看",
  },
];

const FAKE_POPULAR = [
  {
    subject: "呼籲把比特幣納入州儲備！美議員：若八年前這樣做，國庫早就暴漲百倍",
    subjectCn:
      "呼吁把比特币纳入州储备！美议员：若八年前这样做，国库早就暴涨百倍",
  },
  {
    subject:
      "40 年日本遊戲軟實力！Oasys 公鏈全面介紹，如何把傳統遊戲帶向綠洲？",
    subjectCn:
      "40 年日本游戏软实力！Oasys 公链全面介绍，如何把传统游戏带向绿洲？",
  },
  {
    subject: "一鍵秒發迷因幣！Pump.fun 是什麼？發幣教學、平台特色一次看",
    subjectCn: "一键秒发迷因币！Pump.fun 是什麽？发币教学、平台特色一次看",
  },
  {
    subject: "呼籲把比特幣納入州儲備！美議員：若八年前這樣做，國庫早就暴漲百倍",
    subjectCn:
      "呼吁把比特币纳入州储备！美议员：若八年前这样做，国库早就暴涨百倍",
  },
  {
    subject:
      "40 年日本遊戲軟實力！Oasys 公鏈全面介紹，如何把傳統遊戲帶向綠洲？",
    subjectCn:
      "40 年日本游戏软实力！Oasys 公链全面介绍，如何把传统游戏带向绿洲？",
  },
  {
    subject: "一鍵秒發迷因幣！Pump.fun 是什麼？發幣教學、平台特色一次看",
    subjectCn: "一键秒发迷因币！Pump.fun 是什麽？发币教学、平台特色一次看",
  },
  {
    subject: "呼籲把比特幣納入州儲備！美議員：若八年前這樣做，國庫早就暴漲百倍",
    subjectCn:
      "呼吁把比特币纳入州储备！美议员：若八年前这样做，国库早就暴涨百倍",
  },
  {
    subject:
      "40 年日本遊戲軟實力！Oasys 公鏈全面介紹，如何把傳統遊戲帶向綠洲？",
    subjectCn:
      "40 年日本游戏软实力！Oasys 公链全面介绍，如何把传统游戏带向绿洲？",
  },
  {
    subject: "一鍵秒發迷因幣！Pump.fun 是什麼？發幣教學、平台特色一次看",
    subjectCn: "一键秒发迷因币！Pump.fun 是什麽？发币教学、平台特色一次看",
  },
];

// 加密學院分類
const ACADEMY_CATEGORY_MAP = {
  ELEMENTARY: "ELEMENTARY",
  INTERMEDIATE: "INTERMEDIATE",
  ADVANCED: "ADVANCED",
  SPECIAL: "SPECIAL",
};

// 搜尋結果分類
const SEARCH_TAB_MAP = {
  NEWS: "NEWS",
  PROJECT: "PROJECT",
  ACTIVITY: "ACTIVITY",
  FLASH: "FLASH",
  PODCAST: "PODCAST",
};

// 首頁 api
const INDEX_API_ROUTER_PATH = {
  getPrice:
    "https://api-gcp.binance.com/api/v3/ticker?symbols=[%22BTCUSDT%22,%22ETHUSDT%22,%22SOLUSDT%22]&windowSize=1d", // 取得 BTC ETH SOL 幣價
  getGreedIndex: "https://api.alternative.me/fng/", // 取得恐懼貪婪指數
};

// 新聞相關 api
const NEWS_API_ROUTER_PATH = {
  getIndexNewsCarousel: "/api/v1/news/carosuel", // 首頁 banner 輪播
  getIndexKeepNews: "/api/v1/news/keep", // 首頁釘選新聞
  getNewsListByCategory: "/api/v1/news/categories/", // 取得特定類別新聞列表
  getNewsBySlug: "/api/v1/news/slug", // 依 slug 取得單篇新聞內容
  getNewsByNewsId: "/api/v1/news", // 取得單篇新聞內容
  getExtendNews: "/api/v1/news/newsId/extends", // 取得推薦新聞
  getSitemapNews: "/api/v1/news/sitemap", // 取得sitemap新聞
};

// 搜尋相關 api
const SEARCH_API_ROUTER_PATH = {
  getByKeyword: "/api/v1/search/keyword/", // 取得搜尋結果
};

// podcast api
const PODCAST_API_ROUTER_PATH = {
  getPodcastByPage: "/api/v1/podcast/search",
};

// 專欄相關 api
const COLUMN_API_ROUTER_PATH = {
  getColumnListByPage: "/api/v1/news/columnists-with-news", // 取得專欄列表
  getReporterInfo: "/api/v1/news/author-info/", // 取得作者資訊
  getColumnNews: "/api/v1/news/author-news/", // 取得專欄新聞
};

// 快訊相關 api
const FLASH_API_ROUTER_PATH = {
  getFlashListByPage: "/api/v1/flash/list", // 取得快訊列表
  getFlashById: "/api/v1/flash/", // 依 id 取得快訊內容
};

// 專題相關 api
const PROJECT_API_ROUTER_PATH = {
  getProjectListByPage: "/api/v1/project", // 取得專題列表
  getProjectBySlug: "/api/v1/project/", // 取得專題內容
};

// 學院相關 api
const ACADEMY_API_ROUTER_PATH = {
  getAcademyBySlug: "/api/v1/academy/", // 取得學院文章
  getAcademy: "/api/v1/academy", // 取得學院列表
};

// 詞條庫 api
const KNOWLEDGE_API_ROUTER_PATH = {
  getKnowList: "/api/v1/know",
};
// 活動 api
const EVENTS_API_ROUTER_PATH = {
  getEventsBanner: "/api/v1/activity/banners",
  getEvents: "/api/v1/activity/list",
  getEventByEventId: "/api/v1/activity/slug",
};

// crypto read api
const CR_API_ROUTER_PATH = {
  getEdmList: "/api/v1/cread/trial",
  getEdm: "/api/v1/cread/read",
  getSubscribe: "/api/v1/cread/subscribe",
  getUnSubscribe: "/api/v1/cread/unsubscribe",
};

// 活動類別
const EVENTS_FILTER_MAP = {
  ALL: "ALL", // 全部
  ACTIVITY: "ACTIVITY", // 進行中
  COMING: "COMING", // 即將開始
  OVER: "OVER", // 已截止
};

// 語系文檔用
const I18N_NAMESPACES = {
  HEADER: ["header"],
  FOOTER: ["footer"],
  MODAL: ["modal"],
  SEARCH: ["search"],
  INDEX: ["index"],
  NEWS: ["news"],
  PROJECT: ["project"],
  COLUMN: ["column"],
  ACADEMY: ["academy"],
  FLASH: ["flash"],
  KNOWLEDGE: ["knowledge"],
  PODCAST: ["podcast"],
  EVENTS: ["events"],
  ABOUT: ["about"],
  TERMS: ["terms"],
  POLICY: ["policy"],
};

// 詞條庫字母
const ALPHABETS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

// 全站廣告欄位
const PROMO_BANNER_POSITION_CODE = {
  ASIDE_SOCIAL: "N101",
  IDLE_PC: "N102_PC",
  IDLE_MB: "N102_MB",
  MB_MENU_TOP: "M101",
};

// 搜尋結果 每頁顯示數量
const SEARCH_DEFAULT_PAGESIZE = {
  NEWS: 12,
  PROJECT: 6,
  ACTIVITY: 12,
  PODCAST: 9,
};

// google tag manager id
const GTM = {
  ID: "GTM-P4JJB6VD",
  IFRAME_SOURCE: "https://www.googletagmanager.com/ns.html?id=GTM-P4JJB6VD",
};

module.exports = {
  CRYPTO_CITY_OFFICIAL_URL,
  CDN_BASE_ORIGIN,
  CDN_BASE,
  PODCAST_OFFICIAL_URL,
  NAV_MENU,
  NAV_MENU_MAP,
  USER_MENU_LINK,
  FOOTER_PAGE_LINK,
  FOOTER_SOCIAL_LINK,
  NEWS_CATEGORY_MAP,
  FAKE_BANNER,
  FAKE_SELECTED,
  FAKE_POPULAR,
  ACADEMY_CATEGORY_MAP,
  INDEX_API_ROUTER_PATH,
  NEWS_API_ROUTER_PATH,
  SEARCH_API_ROUTER_PATH,
  COLUMN_API_ROUTER_PATH,
  EVENTS_API_ROUTER_PATH,
  CR_API_ROUTER_PATH,
  SEARCH_TAB_MAP,
  I18N_NAMESPACES,
  ALPHABETS,
  PODCAST_API_ROUTER_PATH,
  FLASH_API_ROUTER_PATH,
  PROMO_BANNER_POSITION_CODE,
  SEARCH_DEFAULT_PAGESIZE,
  PROJECT_API_ROUTER_PATH,
  ACADEMY_API_ROUTER_PATH,
  EVENTS_FILTER_MAP,
  KNOWLEDGE_API_ROUTER_PATH,
  GTM,
};
